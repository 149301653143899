import React from "react";
import type { RouteObject } from "react-router-dom";
import { collectionFormPaths } from "./paths";
import { Navigate } from 'react-router-dom';

const IndexPage = React.lazy(() => import("../../views/modules/agreement-form/start"));
const LivelinessPage = React.lazy(() => import("../../views/modules/agreement-form/liveliness"));
const TermsAndConditionsPage = React.lazy(() => import("../../views/modules/agreement-form/terms-conditions"));

export const CollectionFormRoutes: RouteObject[] = [
    { path: collectionFormPaths.subRoutes.start, element: <IndexPage /> },
    { path: collectionFormPaths.subRoutes.liveliness, element: <LivelinessPage /> },
    { path: collectionFormPaths.subRoutes.terms_conditions, element: <TermsAndConditionsPage /> },
    { path: "*", element: <Navigate to={collectionFormPaths.subRoutes.start} /> },
];
