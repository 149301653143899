var API_URL = process.env.REACT_APP_API_URL;
var METAMAP_CLIENT_ID = process.env.REACT_APP_METAMAP_CLIENT_ID;
export { API_URL, METAMAP_CLIENT_ID };

export const REQUEST_TIMEOUT = 60000;
// local storage keys
export const STORAGE_KEYS = {
    AGREEMENT_REFERENCE: "lae.agreement.reference",
    AGREEMENT_REFERENCE_DETAILS: "lae.agreement.details",
    AGREEMENT_FORM: "lae.agreement.form"
};