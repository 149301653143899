import React, { } from "react";
import { useRoutes } from 'react-router-dom';
import { Loading } from "../../components/loaders";
import { CollectionFormRoutes } from "../../../routes/collection-form/routes";
import { useMemo } from "react";
import { AgreementFormProvider } from "../../../providers/AgreementFormProvider";

const AgreementForm = () => {
    const elements = useRoutes(CollectionFormRoutes);
    return useMemo(
        () => <AgreementFormProvider><React.Suspense fallback={<Loading />}>{elements}</React.Suspense></AgreementFormProvider>,
        [elements]
    );
};

export default AgreementForm;
