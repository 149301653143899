import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Loading } from "../components/loaders";
import AgreementForm from "./agreement-form";


const Modules = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/*" element={<AgreementForm />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default Modules
