import React, { useState, createContext } from 'react';
import { STORAGE_KEYS } from '../config/index';
import { getSessionItem, setSessionItem } from '../services/storage';
import { useNavigate } from 'react-router-dom';
export const AgreementFormContext = createContext<any>(null);

type Step = {
    id: string,
    title: string,
    description: string,
    status: string,
    route: string,
}

const FormState = {
    AUTH: 'AUTH',
    TERMS: 'TERMS',
}

export const AgreementFormProvider = (props: any) => {
    const navigate = useNavigate();
    const [currentState, setCurrentState] = useState(FormState.AUTH);
    const defaultSteps = [
        {
            id: "Step 1",
            title: "Liveliness Check",
            description: "",
            status: "active",
            route: "/agreement/liveliness"
        },
        {
            id: "Step 2",
            title: "Read & Sign Agreement",
            description: "Please read the terms & conditions, and sign.",
            status: "active",
            route: "/agreement/terms-conditions"
        },
    ]

    const getFormSteps = () => {
        return JSON.parse(getSessionItem(STORAGE_KEYS.AGREEMENT_FORM) as string) || defaultSteps
    }

    const getReference = () => {
        return getSessionItem(STORAGE_KEYS.AGREEMENT_REFERENCE)
    }
    const [formSteps, setFormSteps] = useState<Step[]>(getFormSteps());


    const storeSteps = () => {
        setSessionItem(STORAGE_KEYS.AGREEMENT_FORM, JSON.stringify(formSteps))
    }


    const currentStep = formSteps.find(step => step.status === 'active');
    const currentStepIndex = formSteps.findIndex(step => step.status === 'active');

    const nextStep = () => {
        let steps = [...formSteps];
        steps[currentStepIndex].status = 'completed';
        steps[currentStepIndex + 1].status = 'active';
        setFormSteps(steps);
        storeSteps();
    }

    const previousStep = () => {
        let steps = [...formSteps];
        steps[currentStepIndex].status = 'inactive';
        steps[currentStepIndex - 1].status = 'active';
        setFormSteps(steps);
        storeSteps();
    }

    const checkValidReference = () => {
        if (!getReference()) {
            window && window.location.replace("/agreement");
        }
    }

    const startForm = () => {
        setCurrentState(FormState.AUTH);
        // navigate('/agreement/start')
    }

    const getCurrentState = () => {
        return currentState
    }

    const goToAgreement = () => {
        setCurrentState(FormState.TERMS)
        navigate('/agreement/form/terms-conditions')
    }

    const backToStart = () => {
        const reference = getReference()
        navigate('/agreement?reference=' + reference)
    }


    return (
        <AgreementFormContext.Provider value={{ formSteps, currentStep, currentStepIndex, reference: getReference(), currentState: getCurrentState(), nextStep, previousStep, checkValidReference, startForm, goToAgreement, backToStart }}>
            {props.children}
        </AgreementFormContext.Provider>
    );
};