import Modules from './views/modules';
import React from 'react';
import './views/assets/styles/index.css';

function App() {
  return (
    <Modules />
  );
}

export default App;
