import { STORAGE_KEYS } from "src/config";

export const getItem = (key: string) => {
    return localStorage.getItem(key) || null;
};

export const setItem = (key: string, value: string) => {
    return localStorage.setItem(key, value);
};
export const getSessionItem = (key: string) => {
    return sessionStorage.getItem(key) || null;
};

export const setSessionItem = (key: string, value: string) => {
    return sessionStorage.setItem(key, value);
};

export const clearData = () => { localStorage.clear(); sessionStorage.clear(); }

export const storeAgreementDetails = (requestDetails: any) => {
    setSessionItem(STORAGE_KEYS.AGREEMENT_REFERENCE_DETAILS, JSON.stringify(requestDetails));
};

export const fetchAgreementDetails = (): any => {
    let details = getSessionItem(STORAGE_KEYS.AGREEMENT_REFERENCE_DETAILS);
    if (details) {
        return JSON.parse(details);
    }
    return { access_token: null };
};