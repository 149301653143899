export const collectionFormPaths = {
    base: "/",
    subRoutes: {
        start: "agreement",
        liveliness: "agreement/form/liveliness",
        liveliness_failed: "agreement/form/liveliness-failed",
        terms_conditions: "agreement/form/terms-conditions",
        video_agreement: "agreement/form/video-agreement",
        complete: "agreement/form/complete",
    },
}